import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning-attendence'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, detailsData, dateList, type = 1) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
                },
                { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
                { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
                { text: reportHeadData.address, style: 'web', alignment: 'center' },
                { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const bodySeperate = [
          [
              { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
              { text: ':', style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' },
              { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
              { text: ':', style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' }
          ]
        ]
          bodySeperate.push(
            [
              { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
              { text: ':', style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? data.training_category_bn : data.training_category, style: 'td', alignment: 'left' },
              { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
              { text: ':', style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' }
            ]
            )
            bodySeperate.push(
              [
                { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
                { text: ':', style: 'td', alignment: 'center' },
                { text: data.circular_memo_no, style: 'td', alignment: 'left' },
                { text: vm.$t('elearning_iabm.batch_no'), style: 'th', alignment: 'left' },
                { text: ':', style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? data.batch_bn : data.batch, style: 'td', alignment: 'left' }
              ]
              )
              bodySeperate.push(
                [
                  { text: vm.$t('elearning_iabm.training_start_dates'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: dateFormat(data.training_start_date), style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_iabm.training_end_dates'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: dateFormat(data.training_end_dates), style: 'td', alignment: 'left' }
                ]
              )
          pdfContent.push({
              table: {
                  style: 'tableData',
                  headerRows: 0,
                  widths: ['18%', '3%', '29%', '18%', '3%', '29%'],
                  body: bodySeperate
              },
              layout: {
                hLineWidth: function (i, node) {
                    return 0
                },
                vLineWidth: function (i, node) {
                    return 0
                }
              }
          })
          if (dateList.length) {
            pdfContent.push({ text: vm.$t('elearning_tim.participant_attendance'), style: 'title', alignment: 'center' })
            const firstHeader = [
                { text: vm.$t('globalTrans.sl_no'), style: 'th1', rowSpan: 3, alignment: 'center' },
                { text: vm.$t('elearning_tim.trainee'), style: 'th1', rowSpan: 3, alignment: 'center' },
                { text: vm.$t('globalTrans.designation'), style: 'th', rowSpan: 3, alignment: 'center' },
                { text: vm.$t('elearning_tim.posting_office'), style: 'th', rowSpan: 3, alignment: 'center' },
                // { text: vm.$t('globalTrans.mobile'), style: 'th1', rowSpan: 3, alignment: 'center' },
                { text: vm.$t('globalTrans.date'), style: 'th1', colSpan: dateList.length * 2, alignment: 'center' }
            ]
            // if (dateList.length > 1) {
              for (let i = 0; i < dateList.length; i++) {
                if (i === 0) {
                  firstHeader.push(
                    {}
                  )
                } else {
                  firstHeader.push(
                    {},
                    {}
                  )
                }
              }
            // }
            const allRows = [
              firstHeader
            ]
            const secondHeader = [
              {},
              {},
              {},
              {}
            ]
            const widthList = ['5%', '12%', '12%', '18%']
            dateList.forEach(dateItem => {
              secondHeader.push(
                { text: dateFormat(dateItem.text), style: 'th1', alignment: 'center', bold: true, colSpan: 2 },
                {}
                )
              })
              allRows.push(secondHeader)
            const thirdHeader = [
              {},
              {},
              {},
              {}
            ]
            dateList.forEach(dateItem => {
              thirdHeader.push(
                { text: vm.$t('globalTrans.morning'), style: 'th1', alignment: 'center', bold: true },
                { text: vm.$t('globalTrans.evening'), style: 'th1', alignment: 'center', bold: true }
              )
              widthList.push('*', '*')
            })
            allRows.push(thirdHeader)
            detailsData.filter((data, optionIndex) => {
              let designation
              let office
              if (data.designation_id && parseInt(data.not_here_designation) === 0 && parseInt(data.profession_type) === 1) {
                designation = vm.getDesignation(data.designation_id)
              } else {
                designation = (vm.$i18n.locale === 'bn') ? data.designation_bn : data.designation_en
              }
              if (parseInt(data.profession_type) === 1 && parseInt(data.not_here_office) === 0) {
                office = vm.getOfficeName(data.professional_office_id) + ' , ' + vm.getOrgName(data.professional_org_id)
              }
              if (parseInt(data.profession_type) === 1 && parseInt(data.not_here_office) === 1) {
                office = (vm.$i18n.locale === 'bn') ? data.office_name_bn : data.office_name + ', ' + vm.getOrgName(data.professional_org_id)
              }
              if (parseInt(data.profession_type) !== 1) {
                office = (vm.$i18n.locale === 'bn') ? data.office_name_bn : data.office_name
              }
              const rowItem = [
                { text: vm.$n(optionIndex + 1), style: 'td1', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? data.name_bn : data.name, style: 'td1', alignment: 'center' },
                { text: designation, style: 'td', alignment: 'center' },
                { text: office, style: 'td', alignment: 'center' }
                // { text: vm.$i18n.locale === 'bn' ? data.mobile : data.mobile, style: 'td1', alignment: 'center' }
                // { text: vm.$n('0') + vm.$n(data.mobile, { useGrouping: false }), style: 'td1', alignment: 'center' }
              ]
              dateList.forEach((field, index1) => {
                const isThere = detailsData[optionIndex].dateList.find(dateList => dateList.text === field.text)
                let morningPresent = ''
                let dayPresent = ''
                if (isThere.morning) {
                  morningPresent = vm.$t('elearning_tim.present')
                } else {
                  morningPresent = vm.$t('elearning_tim.absent')
                }
                if (isThere.day) {
                  dayPresent = vm.$t('elearning_tim.present')
                } else {
                  dayPresent = vm.$t('elearning_tim.absent')
                }
                rowItem.push(
                  { text: morningPresent, alignment: 'center', style: 'td1' },
                  { text: dayPresent, alignment: 'center', style: 'td1' }
                )
              })
              allRows.push(rowItem)
            })
            pdfContent.push({
              table: {
                headerRows: 3,
                widths: widthList,
                body: allRows
              }
            })
          }
          pdfContent.push({ text: '', style: 'fertilizer' })
          pdfContent.push({ text: '', style: 'fertilizer' })
          if (i18n.locale === 'en') {
            pdfContent.push({
              text: [
                  vm.$t('tpm_report.report_footer1') + ' ',
                  { text: vm.$t('tpm_report.idsdp'), bold: true },
                  ' ' + vm.$t('tpm_report.report_footer2')
              ],
              style: 'td',
              alignment: 'center'
            })
          }
          if (i18n.locale === 'bn') {
            pdfContent.push({
              text: [
                  { text: vm.$t('tpm_report.idsdp'), bold: true },
                  ' ' + vm.$t('tpm_report.report_footer1') + ' ' + vm.$t('tpm_report.report_footer2')
              ],
              style: 'td',
              alignment: 'center'
            })
          }
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Landscape',
        footer: function (currentPage, pageCount) {
          return [
            {
              columns: [
                {
                  text: vm.$t('tpm_report.page') + ' ' + vm.$n(currentPage.toString()) + ' / ' + vm.$n(pageCount),
                  alignment: 'left',
                  style: 'footerStyle'
                },
                {
                  text: vm.$t('tpm_report.idsdp'),
                  alignment: 'center',
                  style: 'footerStyle'
                },
                {
                  text: vm.$t('tpm_report.footer_date_time') + ' ' + (vm.$i18n.locale === 'bn' ? new Date().toLocaleDateString('bn-BD') : new Date().toLocaleDateString('en-US')) + ', ' + (vm.$i18n.locale === 'bn' ? new Date().toLocaleTimeString('bn-BD', { hour12: false }) : new Date().toLocaleTimeString('en-US', { hour12: false })),
                  alignment: 'right',
                  style: 'footerStyle'
                }
              ],
              margin: [10, 5] // Adjust the margin as needed
            }
          ]
        },
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          footerStyle: {
            fontSize: 8,
            margin: [30, 5, 30, 5]
          },
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header: {
              fontSize: 20,
              bold: false,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 20,
              bold: false,
              margin: [10, -25, 10, 5]
          },
          address: {
              fontSize: 15,
              margin: [0, 0, 0, 5]
          },
          web: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: false,
              margin: [10, 10, 25, 20]
          },
          title: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      if (type === 1) {
        pdfMake.createPdf(docDefinition, null, null, null).print()
      } else {
        pdfMake.createPdf(docDefinition, null, null, null).download()
      }
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
